<template>
    <div class="users">
        <div class="fx" style="border-bottom:1px solid #ccc;padding-bottom:10px;">
            <div>邀请用户</div>
        </div>
        <div style="border-bottom:1px solid #ccc;text-align:center">
            <img style="width:190px;height:190px;border: 2px solid #CD0000;border-radius: 6px;margin:30px 0px 10px;" :src="info.tg_qr" alt="">
            <div>二维码邀请</div>
            <div class="copy">我的邀请码：<b id="wrapper">{{info.user_no}} </b><span @click="fuzhi">复制</span></div>
            <!-- <div class="InviteBtn cursor" @click="show=true">立即邀请用户</div> -->
        </div>
        <div class="members_invited">
            <div style="margin-bottom:15px;">邀请规则</div>
            <div v-html="explain.content"></div>
            <div class="InviteBtn cursor" v-if="info.can_upgrade==true&&info.director_status==false">申请成为理事</div>
            <div class="top">已邀请的会员  <span>{{total}}人</span></div>
            <div class="fx">
                <div v-for="(item,i) in memberList" :key="i" class="flex box">
                    <img :src="item.avatar" alt="">
                    <div>
                        <div>用户昵称：{{item.name}}</div>
                        <div>邀请时间：{{item.created_at.slice(0,10)}}</div>
                    </div>
                </div>
            </div>
            <Page v-if="memberList.length!=0" :pageSize="page_size" :total="total" :current="page" @change="pageChange"/>
        </div>
        <div class="yingyin" v-show="show">
            <div class="yboxs">
                <i class="el-icon-close cursor" @click="show=false"></i>
                <img :src="info.tg_qr" alt="">
                <div>截图分享二维码邀请</div>
            </div>
        </div>
    </div>
</template>

<script>
import {myExtension} from '@/api/user'
import {explain} from '@/api/commit'
export default {
    data() {
        return {
            page_size:10,   //每页条数
            total:0,        //总条数
            page:1,        //当前页
            memberList:[],
            explain:{},
            show:false
        };
    },
    props:{
        info:{
            default:null,
            type:Object
        }
    },
    created() {
        this.getExtension()
        this.getExplain()
    },
    mounted() {

    },
    methods: {
        //获取邀请说明
        getExplain(){
            explain('DIRECTOR_DESC').then(res=>{
                if(res.code==200){
                    this.explain = res.data
                }
            })
        },
        //我邀请的用户
        getExtension(){
            myExtension().then(res=>{
                if(res.code==200){
                    this.memberList = res.data.data
                    this.total = res.data.total
                }
            })
        },
        //分页
        pageChange(val){
            this.page  = val;
            this.getExtension()
        },
        fuzhi(){
            var range = document.createRange();
            range.selectNode(document.getElementById('wrapper'));
            var selection = window.getSelection();
            if (selection.rangeCount > 0) selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            this.$message({message: '已复制成功',type: 'success'});
        }
    }
};
</script>

<style scoped lang="scss">
    .users{
        .copy{
            margin:20px 0px 50px;
            text-align: center;
            span{
                width: 80px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                color: #CC0000;
                border: 1px solid #FF8080;
                border-radius: 4px;
                padding:5px 10px;
                cursor: pointer;
                margin-left: 30px;
            }
        }
        .InviteBtn{
            width: 340px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            color: #fff;
            margin:50px auto;
            border-bottom: 1px solid #ccc;
            background: #CC0000;
            border-radius: 24px;
        }
        .members_invited{
            margin-top: 30px;
            .top{
                margin-top:50px;
                span{
                    color: #CC0000;
                }
            }
            .fx{
                flex-wrap: wrap;
                .box{
                    width: 33%;
                    margin: 10px 0px;
                    img{
                        border-radius: 50%;
                        margin-right: 10px;
                        width: 48px;
                        height: 48px;
                    }
                }
            }
        }
        .yingyin{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(1,1,1,.4);
            z-index: 99;
            .yboxs{
                padding: 20px;
                text-align: center;
                background: #FFFFFF;
                border-radius: 5px;
                position: relative;
                i{
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    font-size:26px;
                }
                img{
                    width:260px;
                    height: 260px;
                }
            }
        }
    }
</style>
