import service from '@/utils/request'

//修改个人资料
export function users_edit(data){
    return service({
        url:'api/member',
        method:'put',
        data
    })
}
//实名认证 - 提交
export function user_auths_add(data){
    return service({
        url:'api/member_auth',
        method:'put',
        data
    })
}
//实名认证详情
export function user_auths_info(){
    return service({
        url:'api/member_auth',
        method:'get',
    })
}
//实名认证 - 修改
export function user_auths_edit(data){
    return service({
        url:'api/member_auth',
        method:'put',
        data
    })
}

//我的邀请用户
export function myExtension(){
    return service({
        url:'web/v1/users/my-tg',
        method:'get',
    })
}